<template lang="pug">
om-modal(name="segments-user-save-modal" width="420px" @beforeOpen="onBeforeOpen")
  template(#modal-header)
    .row
      .col
        om-heading(h5) {{ $t('userSegment.modals.save.title') }}
    .brand-modal-action-icon
      span#save-segment-modal-close.cursor-pointer(
        :disabled="saving"
        @click="$modal.hide('segments-user-save-modal')"
        title="close"
        aria-hidden="true"
      )
        close-icon(:width="12" :height="12")
  template(#modal-body)
    .mb-5
      om-input#user-segment-name-input(
        :label="$t('userSegment.modals.save.label')"
        :error="error"
        :errorText="errorText"
        v-model="segmentName"
      )
  template(slot="modal-footer")
    .d-flex.justify-content-end
      om-button#save-segment-modal-cancel(
        @click="$modal.hide('segments-user-save-modal')"
        :disabled="saving"
      ) {{ $t('cancel') }}
      om-button#save-segment-modal-save.ml-4(primary @click="save" :disabled="saving") {{ $t('save') }}
</template>
<script>
  import { required } from 'vuelidate/lib/validators';
  import OmInput from '@/components/Elements/Input';
  import SEGMENTS from '@/graphql/Segments.gql';
  import UPSERT_SEGMENT from '@/graphql/UpsertSegment.gql';
  import { track } from '@/services/xray';

  export default {
    components: { OmInput },
    data: () => ({
      saving: false,
      segments: [],
      segmentName: null,
      error: false,
      errorText: null,
      frontendRules: [],
    }),
    computed: {
      names() {
        return this.segments.map(({ name }) => [name.en, name.hu]).flat();
      },
    },
    watch: {
      segmentName() {
        this.$v.$reset();
        this.error = false;
        this.errorText = null;
      },
    },
    validations: {
      segmentName: { required },
    },
    apollo: {
      segments: {
        query: SEGMENTS,
        variables: { type: 'userdefined' },
      },
    },
    methods: {
      async onBeforeOpen(e) {
        this.segmentName = null;
        const { frontendRules = [] } = e.params;
        this.frontendRules = frontendRules;
      },
      async save() {
        if (this.saving) return;
        await this.$apollo.queries.segments.refetch();
        if (!this.validateName()) return;
        this.saving = true;
        const success = await this.saveSegment();
        const type = success ? 'success' : 'error';
        const transKey = success ? 'saveSuccess' : 'saveError';
        this.$notify({
          type,
          text: this.$t(`notifications.${transKey}`),
        });
        this.saving = false;
        this.$modal.hide('segments-user-save-modal');
      },
      async saveSegment() {
        const segment = this.getSegmentPayload();

        const {
          data: { result },
        } = await this.$apollo.mutate({
          mutation: UPSERT_SEGMENT,
          variables: { segment },
        });

        if (result) {
          track('segment:save', { name: this.segmentName });
        }

        return result;
      },
      getSegmentPayload() {
        return {
          name: {
            en: this.segmentName,
            hu: this.segmentName,
          },
          icon: null,
          frontendRules: this.frontendRules,
          type: 'userdefined',
        };
      },
      validateName() {
        this.$v.$touch();

        if (this.$v.$error || this.isDuplicate()) {
          this.error = true;
          this.errorText = !this.segmentName
            ? this.$t('fieldRequired')
            : this.$t('notifications.segments.nameExists');
          return false;
        }
        return true;
      },
      isDuplicate() {
        return this.names.includes(this.segmentName);
      },
    },
  };
</script>
